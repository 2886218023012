// import GetInTouch from "components/getInTouch";
// import Featured from "components/homepage/featured";
// import Reviews from "components/homepage/reviews";
// import TranslateAgency from "components/homepage/translateAgency";
// import TranslationServices from "components/homepage/translationServices";
// import WhatChooseUs from "components/homepage/whyChooseUs";
// import Layout from "components/layout";
// import LocationBanner from "components/locationBanner";
// import SEO from "components/seo";
// import React from "react";
// import AboutBanner from "./aboutusbanner";
// import BusinessDetail from "./businessDetail";
// import LanguageServices from "./languageServices";
// import Pioneering from "./pioneering";
// import CustomerFirst from "components/TranslationServices/customerfirst";
// import { Philosophydata } from "./config";
// import OurTeam from "./ourTeam";
// import OurCommitment from "./ourCommitment";
// import OurCustomer from "./ourCustomers";
// import ClientTestimonial from "components/YearGuarantee/clientTestimonial";
// import { TestimonilaData } from "components/FastDelivery/config";
// import Achievement from "./achievement";
// import DeliveryMeans from "components/FastDelivery/deliveryMeans";
// import Mask from "assets/tomeds.svg";
// import NextProject from "components/YearGuarantee/nextproject";
// const Languages = (props) => {
//   const title = "About Tomedes";
//   const description =
//     "Tomedes is a leading translation agency with more than 95,000 customers and thousands of professional translators. Want to get in touch with us? - support@tomedes.com | +1 985 239 0142";
//   const keywords = "about tomedes, about translation company";
//   const slug = "/about-tomedes";
//   const {
//     pageContext: {
//       data: { data },
//     },
//   } = props;

//   const firstWord = data.title_x.split(" ")[0];

//   let filterVowel =
//     typeof firstWord?.[0] === "string"
//       ? firstWord[0].toLowerCase().toString()
//       : "";

//   let vowels = ["a", "e", "i", "o", "u"];

//   let vow = "A";

//   vowels.forEach((item) => {
//     if (filterVowel == item) {
//       vow = "An";
//     }
//   });
//   const nextbutton = (
//     <button className="bg-[#ff7b16] px-4 py-2 rounded-md">
//       Let’s Talk Real Language Solutions
//     </button>
//   );

//   return (
//     <Layout>
//       {data.slug === slug ? (
//         <SEO
//           title={title}
//           description={description}
//           keywords={keywords}
//           slug={slug}
//         />
//       ) : (
//         <>
//           <SEO
//             title={data.Title_tag_value}
//             description={data.Meta_tag_desc}
//             keywords={data.Meta_tag_keywords}
//             slug={data.slug}
//           />
//           {/* <LocationBanner
//             title={data.title_x}
//             content={data.Meta_tag_desc}
//             formOrangeHead="Get in touch"
//             formWhiteHead=""
//             backgroundCol="bg-midBlue"
//             // styleOne={variant || variant_mobile}
//             // styleOne="1"
//           /> */}
//           {/* <TranslateAgency firstWord={firstWord} vow={vow} /> */}
//         </>
//       )}
//       {/* <div
//         className={"service_wrapper " + data.slug.replace(/[^a-zA-Z]+/g, "")}
//         dangerouslySetInnerHTML={{ __html: data.SEOCMSPageContent }}
//       /> */}
//       <AboutBanner />
//       <BusinessDetail />
//       <LanguageServices />
//       <Pioneering />
//       <CustomerFirst
//         title=""
//         description=""
//         heading="Tomedes Business Philosophy"
//         customerFirstdata={Philosophydata}
//       />
//       <OurTeam />
//       <OurCommitment />
//       <OurCustomer />
//       <ClientTestimonial
//         text="Testimonials"
//         description="Discover real feedback and experiences from those who've partnered with us for their language solutions."
//         btntext="See Testimonials"
//         testimoniladata={TestimonilaData}
//       />
//       <Achievement />
//       <div className="bg-[#F5F9FF] ">
//         <DeliveryMeans
//           heading="Tomedes Expands with Specialized Websites"
//           subheading="At Tomedes, we’re broadening our horizons, exemplifying our client-focused approach by unveiling specialized websites like MachineTranslation.com, FormalTranslations.com, LegalTranslations.com and LexTranscribe.com."
//           text="Our customer-first philosophy ensures fast, accessible, and cost-effective language services without compromising on quality."
//           // button={buttonDelivery}
//           Image={Mask}
//         />
//       </div>
//       <NextProject
//         heading="Ready to Translate Your Vision into Reality?"
//         subheading="Embark on a journey of seamless communication with Tomedes. Let's discuss how our tailored language solutions can support your global aspirations."
//         button={nextbutton}
//       />
//     </Layout>
//   );
// };
// export default Languages;

import GetInTouch from "components/getInTouch";
import Featured from "components/homepage/featured";
import Reviews from "components/homepage/reviews";
import TranslateAgency from "components/homepage/translateAgency";
import TranslationServices from "components/homepage/translationServices";
import WhatChooseUs from "components/homepage/whyChooseUs";
import Layout from "components/layout";
import LocationBanner from "components/locationBanner";
import SEO from "components/seo";
import React from "react";

const Languages = (props) => {
  const title = "About Tomedes";
  const description =
    "Tomedes is a leading translation agency with more than 95,000 customers and thousands of professional translators. Want to get in touch with us? - support@tomedes.com | +1 985 239 0142";
  const keywords = "about tomedes, about translation company";
  const slug = "/about-tomedes";
  const {
    pageContext: {
      data: { data },
    },
  } = props;

  const firstWord = data.title_x.split(" ")[0];

  let filterVowel =
    typeof firstWord?.[0] === "string"
      ? firstWord[0].toLowerCase().toString()
      : "";

  let vowels = ["a", "e", "i", "o", "u"];

  let vow = "A";

  vowels.forEach((item) => {
    if (filterVowel == item) {
      vow = "An";
    }
  });

  return (
    <Layout>
      {data.slug === slug ? (
        <SEO
          title={title}
          description={description}
          keywords={keywords}
          slug={slug}
        />
      ) : (
        <>
          <SEO
            title={data.Title_tag_value}
            description={data.Meta_tag_desc}
            keywords={data.Meta_tag_keywords}
            slug={data.slug}
          />
          <LocationBanner
            title={data.title_x}
            content={data.Meta_tag_desc}
            formOrangeHead="Get in touch"
            formWhiteHead=""
            backgroundCol="bg-midBlue"
            // styleOne={variant || variant_mobile}
            // styleOne="1"
          />
          <TranslateAgency firstWord={firstWord} vow={vow} />
        </>
      )}
      <div
        className={"service_wrapper " + data.slug.replace(/[^a-zA-Z]+/g, "")}
        dangerouslySetInnerHTML={{ __html: data.SEOCMSPageContent }}
      />

      <Reviews
        backgroundCol="bg-darkBlue"
        textcol="text-white"
        socialIconsVisible={true}
      />
      <TranslationServices
        backgroundCol="bg-midBlue"
        LanguageName={firstWord}
        // styleOne={variant || variant_mobile}
        // styleOne="1"
      />
      <Featured backgroundCol="bg-darkBlue" />
      <WhatChooseUs backgroundCol="bg-midBlue" LanguageName={firstWord} />
      <GetInTouch backgroundCol="bg-darkBlue" />
    </Layout>
  );
};
export default Languages;
